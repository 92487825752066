.volunteerfcss .containborder {
    border: 1px solid;
    padding: 70px;
    margin-bottom: 20px;
}

.valun_header_height {
    margin-top: 100px;
}

.volunteerfcss .volunteersign {
    text-align: center;
    margin-top: 20px;
    font-size: 35px;
    font-family: 'docs-Roboto';
    font-weight: 400;
    color: #202124;
    line-height: 135%;
}

.txtassocite {
    font-size: 15px;
}

.volunteerfcss .label-head {
    margin-left: 0px;
}

.volunteerfcss .btnvSubmit {
    margin-left: 120px !important;
}

.volunteerfcss .btnsubvalu {
    height: 40px;
    width: 70%;
    padding: 8px !important;
    font-size: 17px;
    font-weight: 600;
    background-color: #D19426;
    margin-top: 13px !important;
    font-family: 'docs-Roboto';
    color: black;
}

.volunteerfcss .btnsubvalu:hover {
    background-color: #D19426;
    color: black;
}

.volunteerfcss .googlefo {
    text-align: center;
    font-size: 15px;
    color: #6F89AB;
}

.volunteerfcss .submitform {
    margin-top: 30px;
}

.volunteerfcss .event-time-header {
    border-bottom: 1px solid red;
}

.volunteerfcss .emailbordercs {
    border: 1px solid #E4E4E4;
    padding: 30px;
    border-radius: 7px;
    margin-top: 30px
}

.event-time-header {

    border-bottom: 1px solid #E4E4E4;
    width: 100%;
}

.txteventtime {
    border: 1px solid #E4E4E4;
    width: 100%;
    margin-left: 45px;
    border-radius: 4px;
}

.volunteerfcss .label-head {
    font-size: 15px !important;
    font-weight: 700;
    font-family: 'Roboto';
    margin-bottom: 10px;
}

.volunteerfcss .volunteerdrpform {
    border-width: 1px;
    border-style: solid;
    border-color: var(--ast-border-color);
    border-radius: 2px;
    background: #fafafa;
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 11px;
    border: 1px solid #DDDDDD;
    width: 100%;
    -webkit-appearance: none;
}

.selectwrappervolun ::after {
    content: "\25BC" !important;
    position: absolute;
    top: 73%;
    right: 30px;
    transform: translateY(-50%);
    pointer-events: none;
}

.volunteerfcss .inputattcv {
    font-size: 18px;
    width: 100%;
    line-height: 1.3;
    font-weight: 400;
}

@media only screen and (max-width: 600px) {
    .selectwrappervolun ::after {
        content: "\25BC" !important;
        position: absolute;
        top: 82%;
        right: 30px;
        transform: translateY(-50%);
        pointer-events: none;
    }
}