.signupcss #myDivonetwo  {
    background-image: url('../Images/Signup-learner.jpg');
    background-color: #675b5b;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    margin-bottom: 30px;
}


.signupcss .txtsinupone {
    text-align: center;
    font-size: 15px;
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
    color: honeydew;
}

.signupcss .txtfullmargin {

    padding-top: 195px;
}

.signupcss .txt-emailid {
    font-size: 20px;
    font-family: Roboto;
    color: #FFFFFF;
}

.signupcss .txtemailcs {
    width: 100%;
    height: 30px;
    font-size: 15px;
}

.signupcss .mrtop {
    margin-top: 20px;
}

.signupcss .txtemailcsaddress {
    width: 100%;
    height: 80px;
    font-size: 20px;
}

.signupcss .buttonsuccescss {
    margin-top: 25px;

}

.signupcss .butacc {
    font-size: 20px;

}

.signupcss .buttonsuccescss .butacc {
    padding: 15px !important;
    border-radius: 5px !important;
    font-size: 12px;
    font-weight: 500;
}

.signupcss .buttonsuccescss .col-3 {
    max-width: 35% !important;
    margin-top: 19px !important;
}

/* //nithiya */
.signupcss .buttonsuccescss .butacc {
    padding: 7px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    width: 126px !important;
    height: 40px !important;
    background-color: #D19426;
}

.signupcss {
    overflow-x: hidden; 
  }