.opportunitypagecss .evetop {
    font-family: Roboto;
    color: #333333;
    line-height: 19px;
    font-weight: 400;
    font-size: 15px;
}

.opportunitypagecss .csshemap {
    margin-top: 3px;
    margin-right: 2px;
    margin-left: 4px;
    color: #828282;
    height: 15px;
}

.opportunitypagecss .multiloc {
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    color: #828282;
}

.opportunitypagecss .imglog {
    margin-top: 15px;
    margin-bottom: 50px;
}

.opportunitypagecss .btnshare {
    width: fit-content;
    padding: 0px 15px;
    height: 35px;
    background-color: #D19426;
    color: #000;
    font-weight: 700;
    font-size: 15px;
    font-family: Roboto;
}

.opportunitypagecss .hellowthtopex {
    font-size: 15px;

}

.opportunitypagecss .txthellowth {
    color: #D19426;
    font-size: 14px
}

.opportunitypagecss .txthellowthtop {
    font-size: 16px;
    font-family: Roboto;
}


.opportunitypagecss .experince {

    color: #D19426;
    font-size: 18px;
}

.opportunitypagecss .experincebottom {

    font-size: 18px;
}

.opportunitypagecss .txtinterser {
    font-size: 18px;
    margin-left: 45px
}

.opportunitypagecss .experinceone {
    /* margin-left: 47px; */
    font-size: 18px;
}

.opportunitypagecss .txthellowthfull {
    margin-left: 47px;

}

.opportunitypagecss .mrtop {
    margin-top: 20px;
    font-family: Roboto;
}

.opportunitypagecss .imgdesignercallout {
    width: 100%;
    height: auto;
    background-color: #D19426;
    padding: 38px;
}

.opportunitypagecss .txthellowthone {
    /* margin-left: 45px; */
    font-size: 16px;
    color: #D19426;
    margin-bottom: 26px
}

.opportunitypagecss .txthellowthbottom {
    font-size: 16px;
}

.opportunitypagecss .padcss {
    padding: 35px;
}




.opportunitypagecss .imgdesignercalloutinner {
    width: 99%;
    height: auto;
    background-color: #F3E8C3;
    border-radius: 30px;
}

.opportunitypagecss .txthellowthfull {
    font-size: 15px;
    /* margin-left: 45px; */
}

.opportunitypagecss .applyfill {
    text-align: center;
    font-size: 30px;
    color: #D19426;
    font-family: Roboto;
}

.opportunitypagecss .applyfilltop {
    font-size: 61px;
    margin-bottom: 26px;
    text-align: center;
    color: #D19426;
    font-family: Roboto;
}

.opportunitypagecss .txttarash {
    font-size: 15px;
    color: #A1A1A1;
    font-family: Roboto;
}

@media only screen and (max-width: 767px) {


    .opportunitypagecss .main-content-section .left-section {
        display: none !important;
    }

    .opportunitypagecss .main-content-section .right-section {
        margin-left: 40px;
        max-width: 80%;
        flex: 0 0 80%;
    }

    .tags_fit {
        /* flex-wrap: wrap; */
        align-items: 'center';
        /* gap: '15px'; */
        padding-left: '0px' !important;
        text-wrap: nowrap;


    }
}

.event-junior-designer-text {
    text-decoration: underline !important;
    color: #134F5C;
    font-family: Roboto;
    font-weight: 400;
    line-height: 19px;
    font-size: 15px;
}

.event-tarasha-text {
    font-size: 16px;
    color: #828282;
    font-family: Roboto;
    font-weight: 500;
    line-height: 20px;
}

button.event-buttons.btn.btn-secondary {
    margin: 0px 5px !important;
}

.event-posted-text {
    font-size: 16px;
    color: #828282;
    font-family: Roboto;
    font-weight: 400;
    line-height: 20px;
}

.event-junior-tarasha-text {
    font-weight: 400;
    line-height: 35px;
    color: #333333;
    font-size: 27px;
}

.event-buttons {
    line-height: 25px !important;
    color: #333333 !important;
    border-radius: 30px !important;
    font-family: Roboto;
    font-size: 18px !important;
    background-color: #E7EDEF !important;
    border: 0 !important;
    width: fit-content !important;
    padding: 5px 15px !important;

}

.event-hello-text {
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    font-family: Roboto;
}

.span-text {
    color: #134F5C !important;
    font-weight: 500;
    font-size: 16px;
}

.event-hello-text1 {
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    font-family: Roboto;
    line-height: 28px;
}

.event-span-text {
    font-weight: 700;
    font-size: 15px;
    color: #000000;
    font-family: Roboto;
}

.opper-individual-img {
    border-radius: 50%;
    width: 181.693px;
    height: 180px;
    object-fit: scale-down;
}

.right-icons {
    font-size: 13px;
}

.opportunity-link {
    text-decoration: none !important;
}

.opp_height {
    margin-top: 150px;
}

/* Nithya css  */

.plist {
    color: #828282;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0px !important;
}

.spanlist {

    color: #333;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 1px;
    text-wrap: pretty;


}

.onlist {

    color: #828282;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 14px;
}

.job-list {

    color: #333;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 198%;
    /* 65.8px */
}

.event-buttons:hover {
    background-color: #E7EDEF !important;
}

.click_tag {
    background: #134f5c !important;
}

.non_click_tag {
    background: #dedede !important;
    cursor: auto !important;
}

.click_tag:hover {
    background: #134f5c !important;
}


.non_click_tag:hover {
    background: #dedede !important;
    cursor: auto !important;
}



/* //nithya */
.social-share-overlay {
    position: fixed;
    top: 79px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    right: 397px;
}

/* css for event cit state  */
.equal-spacing {
    margin-right: 1px;
    /* margin-left: 7px; */
}

.artisan-link {
    margin-top: 5px;
}

.locationcol-event {
    color: #828282;
    font-family: Roboto;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 0;
    margin-top: 2px !important;
}


.opportunity-url {
    margin: 0px 6px;
    text-decoration: none;
    color: #828282;
    font-family: Roboto;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
}

.bi-word {
    font-size: 17px;
    color: #828282;
    margin-top: 3px;
}

.heightworld {
    font-size: 18px;
    color: #828282;
    margin-top: 2px;
}

.heightworld-url {
    font-size: 18px;
    color: #828282;
}
.heightworld-url-oppo {
    font-size: 18px;
    color: #828282;
    margin-top: 7px;
}

h3.event-tarasha-text {
    margin-left: 0px !important;
}

svg.cssheight {
    margin-top: 8px !important;
}

.url-word {
    color: #828282;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 25px;
    font-style: italic;
    margin-top: 5px;
    text-decoration: none;
}
.url-mobile {
    text-decoration: none;
    margin-top:5px;
}
.location-opportuniy {
    color: #828282;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 25px;
    font-style: italic;
    margin: 10px 0px 0px 15px;
}
.map-opprtunity {
    color: #828282;
    font-size: 17px;
}
.event-link {
    text-decoration: none;
    margin: 3px 11px;
}


@media only screen and (max-width: 600px) {
    /* svg.cal-icon {
        width: 25px !important;
        height: 25px !important;
        margin-bottom: 13px !important;
    } */

    .url-word {
        word-wrap: break-word !important;
        max-width: 100% !important;
    }
    .individual-para a{
        word-wrap: break-word !important;
        max-width: 100% !important;
    }

    /* .heightworld {
        width: 35px !important;
    } */
    .location-opportuniy  {
        margin: 9px 0px 0px 0px !important;
    }

    .url-mobile {
        text-decoration: none;
        padding: 0px 0px 0px 11px !important;
    }
    .url-view {
        padding: 0px 0px 0px 5px !important;
    }
    .map-opprtunity {
        margin-right: 18px !important;
        font-size: 19px !important;
    }

    /* svg.cssheight{
        margin-top: 10px !important;

    } */
    /* .cssheight {
        font-size: 24px !important;
    } */
}

@media only screen and (max-width: 600px) {
    p.plist {
        font-size: 14px !important;
        padding-top: 10px !important;
    }

    Button.tag-mob {
        margin-bottom: 10px !important;
    }

    button.spanlist.tag-mob {
        margin-bottom: 30px !important;
        margin-right: 10px !important;
    }

    .event-buttons.tag-mob {

        margin-bottom: 10px !important;
    }

    .tag-mob {

        margin-bottom: 10px !important;
    }
}


@media screen and (max-width: 600px) {
    button.event-buttons.btn.btn-secondary {
        margin: 0px !important;
    }

}

@media only screen and (max-width: 600px) {
    .tags_fit .mob-website {
        margin-left: 13px !important;
    }
    .mob-location {
        padding: 0px 0px 0px 3px !important;
    }
    .event-link {
        text-decoration: none;
        margin: 0px 9px !important;
    } 

}