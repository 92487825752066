.artisanprofilecss .paragrapEdit {
    font-size: 20px;
    font-weight: 500;
    font-family: Roboto;
}


.artisanprofilecss .paragraphsize {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0px;
    font-family: Roboto;
}

.artisanprofilecss .artisanmrgtop {
    margin-top: 45px;
}

.parent-div {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    background-color: #FCFAF6;
    border-radius: 10px;
    margin-top: 30px;

}


.paragraphsize {
    margin: 10px 0;
}

.artisanprofilecss .selectop {
    width: 15%;
    border-bottom: 1px solid;
    font-size: 12px;
}

.artisanprofilecss .editdrpmarg {
    border: none;
    border-bottom: 1px solid;
    font-size: 15px;
    margin-top: 35px;
}

.artisanprofilecss .areaofworkone {
    font-size: 15px;
    color: #907d7d;
    margin-top: 30px;
    font-family: Roboto;
}

.artisanprofilecss .nonedit {
    font-size: 15px;
    color: #907d7d;
    margin-top: 24px;
    font-family: Roboto;
    font-weight: 500;
}

.artisanprofilecss .ghtkjhsnumber {
    font-size: 15px;
    font-weight: 300;
    font-family: Roboto;
    color: #907d7d;
    margin: 0px;
    margin-top: 5px;
}

.artisanprofilecss .artisantextcss {
    font-size: 20px;
    font-family: Roboto;
}

.artisanprofilecss .profilecss {
    font-size: 15px;
    margin-top: 45px;
    margin-bottom: 20px;
    font-family: Fira sans-serif;
}

.artisanprofilecss .imagesprofile {
    border-radius: 80px;
}

.artisanprofilecss .mempromt {
    margin-top: 65px;
    margin-bottom: 20px;
}

.artisanprofilecss .under-line {
    border-bottom: 1px solid;
    margin-top: 35px;
}

.artisanprofilecss .areaofwork {
    font-size: 15px;
    color: #907d7d;
}


.artisanprofilecss .texttilededesign {
    font-size: 20px;
    font-family: Roboto;
}

.artisanprofilecss .selectoptiont {
    height: 26px;
    border: none;
    border-bottom: 1px solid;
    margin-bottom: 10px;
    font-size: 20px;
    font-family: Fira sans-serif;
}

.artisanprofilecss .cssfontheight {
    font-size: 25px;
    margin-left: 15px;
    margin-top: 5px
}

.artisanprofilecss .facebarjun {
    text-align: center;
    background-color: #E8E8E8;
    width: 17%;
    height: 26px;
    border-radius: 02;
    margin-left: 0px;
    margin-top: 0px;
    padding: 2px;
    font-size: 15px;
    border-radius: 5px;
    font-weight: 500;
}

.artisanprofilecss .texttilede {
    font-family: Roboto;
    font-size: 20px;
}

.rowone {
    display: flex;
    align-items: center;
}

.artisanprofilecss .fbpading {
    margin-left: 13px;
    margin-top: 0px;
    font-size: 15px;
    padding: 7px;
}

.artisanprofilecss .xesize {
    margin-left: 0px;
    margin-top: 0px;
    font-size: 20px;
    padding: 7px;
}

.artisanprofilecss .noneditwebsite {
    font-size: 15px;
    color: #907d7d;
   
}

.artisanprofilecss .addbtn {
    background-color: green;
    width: 9%;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    font-family: Roboto;
    height: 39px;
}

.artisanprofilecss .fbpading {
    padding: 2px;
}

.artisanprofilecss .facebarjun {
    text-align: center;
    background-color: #E8E8E8;
    width: 17%;
    height: 26px;
    border-radius: 02;
    margin-left: 0px;
    margin-top: 5px;
    padding: 2px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 500;
}

.artisanprofilecss .btnsizeartone {
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.artisanprofilecss .btnsizeartonetwo {
    margin-left: 0px;
}

.artisanprofilecss .btnstyleone {
    color: #000 !important;
    border-radius: 17px !important;
    width: 100px;
    background-color: #E7EDEF !important;
    border: none !important;
    font-size: 15px !important;
    margin-bottom: 10px;
    padding: 0px !important;
    margin-right: 10px !important;
    height: 30px;
}

.artisanprofilecss .loremipsum {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 500;
}

.artisanprofilecss .back-col-pro {
    background-color: #F3F6F7;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
}

.artisanprofilecss .noneditlong {
    font-size: 17px;
    color: #907d7d;
    font-family: Roboto;
}

.artisanprofilecss .firstp {
    margin-top: 5px;
    margin-left: 6px;
    padding-left: 70px;
    font-family: Roboto;
}

.artisanprofilecss .secondp {
    font-size: 17px;
    margin-bottom: 33px;
    margin-left: 25px;
    font-family: Roboto;
}

.artisanprofilecss .back-col-probottom {
    /* width: 35%; */
    background-color: #F3F6F7;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 20px
}

.artisanprofilecss .selectoptiontlevel {
    width: 80%;
    height: 24px;
    border: none;
    border-bottom: 1px solid;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    font-family: Fira sans-serif;
}

.artisanprofilecss .checkbox-label {
    font-size: 20px;
    margin-top: 5px;
    padding-top: 4px;
    font-weight: 500 !important;
    font-family: Roboto;
}


.artisanprofilecss .mrgartprofilebottom {
    margin-bottom: 100px;
}

.artisanprofilecss .Website {
    margin-bottom: 0px;
    font-size: 20px;
    color: #907d7d;
}

.artisanprofilecss .noneditfieldlon {
    font-size: 17px;
    color: #907d7d;
    font-family: Roboto;
}

.artisanprofilecss .mrgartpro {
    margin-bottom: 100px;
}