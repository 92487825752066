::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #000;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
 color:    #000;
 opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
 color:    #000;
 opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color:    #000;
}
::-ms-input-placeholder { /* Microsoft Edge */
 color:    #000;
}

::placeholder { /* Most modern browsers support this now. */
 color:    #000;
}

.header_icon {
  height: 25px;
  width: 312px;
  margin-left: 1088px;
  margin-right: 12px;
  margin-top: 10px;
}

.header_icon_size {
  height: 20px;
  width: 20px;
}

.header_icon_size1 {
  height: 20px;
  width: 20px;
  /* margin-left: 20px; */
}

.getinvolvedbutton {
  margin-left: 20px;
  font-size: 18px;
  background: #134f5c;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; */
  border-radius: 5px;
  color: #fff !important;
  /* color: var(--ast-global-color-5); */
  padding: 3px 10px;
  /* padding-bottom: 6px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 6px; */
}

.getinvolvedbutton:hover {
  margin-left: 20px;
  font-size: 18px;
  background: #134f5c;
  border-radius: 5px;
  /* color: var(--ast-global-color-5); */
  padding: 3px 10px;
  color: #fff !important;
}

.footerpadding {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
}



.footerh6 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.footerh6_1 {
  font-size: 24px;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
}

.footerh6_2 {
  font-size: 13px !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #fff !important;
}


a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #fff;
  /* color: #333 !important; */
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  padding: 60px 0;
  /* min-height: 100vh;*/
}

.footer {
  background: #134F5C;
  /* background: linear-gradient(105deg, #6e99e6, #093c94); */
  padding-top: 35px;
  padding-bottom: 40px;
  margin-top: 40px;
  /* padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px; */
}

/*END FOOTER SOCIAL DESIGN*/
.single_footer {}

@media only screen and (max-width:768px) {
 
  .single_footer {
    margin-bottom: 30px;
  }

  .getinvolvedbutton {
    margin-left: 20px;
    /* font-size: 1px; */
    background: #134f5c;
    border-radius: 5px;
    color: #fff !important;
    padding: 3px 10px;
  }
}

.single_footer h4 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
}

/* .single_footer h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: #fff;
    margin-top: 20px;
} */
.single_footer p {
  color: #fff;
}

.single_footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.single_footer ul li {}

.single_footer ul li a {
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  /* line-height: 36px; */
  text-wrap: nowrap;
  font-size: 14px;
  /* font-size: 15px; */
  /* text-transform: capitalize; */
  cursor: pointer;
  text-decoration: none;
}

.single_footer ul li a:hover {
  color: #fff !important;
  /* color: #fff; */
  text-decoration: none;
}
.footer-padding {
  padding-left: 25px;
}

.single_footer_address {}

.single_footer_address ul {}

.single_footer_address ul li {
  color: #fff;
  margin-bottom: 25px;
}

.single_footer_address ul li span {
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}

.contact_social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 100%;
}

.subscribe__input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 60px;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0 150px 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

@media only screen and (max-width:768px) {
  
  .subscribe__input {
    padding: 0 50px 0 20px;
  }
  .footer-padding {
    padding-left: 0px;
  }

  .getinvolvedbutton {
    margin-left: 20px;
    /* font-size: 1px; */
    background: #134f5c;
    border-radius: 5px;
    color: #fff !important;
    padding: 3px 10px;
  }
}

.subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}

.subscribe__btn i {
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width:768px) {
 .subscribe__btn {
    right: 0px;
  }

  .getinvolvedbutton {
    margin-left: 20px;
    /* font-size: 1px; */
    background: #134f5c;
    border-radius: 5px;
    color: #fff !important;
    padding: 3px 10px;
  }
}

.subscribe__btn:hover i {
  color: #fff;
  text-decoration: none;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
}

p {
  margin-bottom: 1em;
}

h6 {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}

/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
  margin-top: 40px;
}

.social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.social_profile ul li {
  float: left;
}

.social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width:768px) {
  .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .getinvolvedbutton {
    margin-left: 20px;
    /* font-size: 1px; */
    background: #134f5c;
    border-radius: 5px;
    color: #fff !important;
    padding: 3px 10px;
  }
}

@media only screen and (max-width:480px) {
  .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .getinvolvedbutton {
    margin-left: 20px;
    /* font-size: 1px; */
    background: #134f5c;
    border-radius: 5px;
    color: #fff !important;
    padding: 3px 10px;
  }
}

.social_profile ul li a:hover {
  background: #fff;
  border: 1px solid #ff3666;
  color: #fff;
  border: 0px;
  text-decoration: none;
}

/*END SOCIAL PROFILE CSS*/
.copyright {
  margin-top: 70px;
  padding-top: 40px;
  color: #fff;
  font-size: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  text-align: center;
}

.copyright a {
  color: #01c7e9;
  transition: all 0.2s ease 0s;
}

.copyright a:hover {
  color: #fff;
  text-decoration: none;
}


/* header */

/* CSS Document */

@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif);

a {
  color: #FFF;
}


#header_container {
  margin: 0 auto;
  max-width: 890px;
}


.header_toggle,
[id^=drop] {
  display: none;
}

.header_toggle1,
[id^=drop] {
  display: none;
}

.header_logo {
  margin-top: 22px;
  margin-left: 11px;
}

/* Giving a background-color to the nav container. */
nav {
  margin: 0;
  padding: 0;
  background-color: #D19426;
}

#logo {
  display: block;
  padding: 0 30px;
  float: left;
  font-size: 20px;
  line-height: 60px;
}

nav:after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  /* float: right; */
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

/* Positioning the navigation items inline */
nav ul li {
  margin: 0px;
  padding: 0px 5px;
  display: inline-block;
  float: left;
  cursor: pointer;
  /* background-color: #D19426; */
}

.header_list {
  background-color: #134F5C;
}

/* Styling the links */
nav a {
  display: block;
  padding: 14px 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 60px;
  text-decoration: none;
  font-family: Hongkong, Helvetica, Arial, sans-serif;
  font-style: inherit;
  color: #000000 !important;
  /* color: var(--ast-global-color-8); */
}

a.list-anchor-text {
  font-size: 16px;
  color: #000000;
  /* color: #fff !important; */
  line-height: 30px;
  cursor: pointer;
}

a.list-anchor-text_clr {
  color: #fff !important;
}

a.list-anchor-text_clr:hover {
  color: #fff !important;
}

a.list-anchor-text:hover {
  color: #000000 !important;
  /* color: #fff !important; */
}

/* nav ul li ul li:hover {
  background: #000000;
} */

/* Background color change on Hover */
/* nav a:hover { 
	background-color: #000000; 
} */
a:hover {
  color: #000000 !important;
  /* color: #fff !important; */
  text-decoration: none !important;
}

nav ul ul {
  display: none;
  position: absolute;
  top: 60px;
}

nav ul li:hover>ul {
  display: inherit;
}

nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
}


nav ul ul ul li {
  position: relative;
  top: -60px;
  left: 170px;
}


/* Change ' +' in order to change the Dropdown symbol */
li>a:after {
  content: ' +';
}

li>a:only-child:after {
  content: '';
}


/* Media Queries
--------------------------------------------- */

@media all and (max-width : 768px) {

  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }

  /* nav {
    margin: 0;
    background: #D19426 !important;
  } */
  nav {
    margin: 0;
    /* background: #D19426 !important; */
  }

  .move-right {
    margin-right: 20px !important;
  }

  .color-change {
    color: #000 !important;
    font-size: 24px;
  }

  .show {
    background-color: #d19426 !important;
  }

  .language-text {
    text-align: justify !important;
  }

  /* Hide the navigation menu by default */
  /* Also hide the  */
  .header_toggle+a,
  .menu {
    display: none;
  }

  .header_toggle1+a,
  .menu {
    display: none;
  }

  /* Stylinf the toggle lable */
  .header_toggle {
    display: block;
    background-color: #D19426;
    /* background-color: #254441; */
    padding: 30px 20px;
    color: #000000;
    font-size: 18px;
    text-decoration: none;
    border: none;
    float: right;
    margin-right: 13px;
  }

  .header_toggle1 {
    display: block;
    background-color: #D19426;
    /* background-color: #254441; */
    padding: 14px 20px;
    color: #000000;
    font-size: 18px;
    text-decoration: none;
    border: none;
  }

  .header_toggle:hover {
    background-color: #D19426;
    /* background-color: #000000; */
  }

  /* Display Dropdown when clicked on Parent Lable */
  [id^=drop]:checked+ul {
    display: block;
  }

  /* Change menu item's width to 100% */
  nav ul li {
    display: block;
    width: 100%;
  }

  nav ul ul .header_toggle,
  nav ul ul a {
    padding: 0 40px;
  }

  nav ul ul ul a {
    padding: 0 80px;
  }

  nav a:hover,
  nav ul ul ul a {
    /* background-color: #D19426; */
    /* background-color: #000000; */
  }

  nav ul li ul li .header_toggle,
  nav ul ul a,
  nav ul ul ul a {
    padding: 14px 20px;
    color: #000000;
    /* color: #FFF; */
    font-size: 17px;
  }


  nav ul li ul li .header_toggle,
  nav ul ul a {
    background-color: #D19426;
    /* background-color: #212121; */
  }

  /* Hide Dropdowns by Default */
  nav ul ul {
    float: none;
    position: static;
    color: #ffffff;
    /* has to be the same number as the "line-height" of "nav a" */
  }

  /* Hide menus on hover */
  nav ul ul li:hover>ul,
  nav ul li:hover>ul {
    display: none;
  }

  /* Fisrt Tier Dropdown */
  nav ul ul li {
    display: block;
    width: 100%;
  }

  nav ul ul ul li {
    position: static;
    /* has to be the same number as the "width" of "nav ul ul li" */

  }

}

@media all and (max-width : 330px) {

  nav ul li {
    display: block;
    width: 94%;
  }

}



.toggle_icon {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}




/*form css */
.label-head {
  font-size: 16px;
  font-weight: 700;
  margin-top: 15px;
}

.gsection_title {
  font-size: 20px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 1.2em;
}

.required-color {
  color: #c02b0a;
  font-style: italic;
  font-weight: 400;
}


.form-control {
  display: block;
  width: 100%;
  height: calc(2.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem;
  font-size: 1.4rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.file-change {
  -webkit-box-shadow: inset 0 1px 1px #fff !important;
  border: none !important;
}

.text-area {
  width: 100%;
  height: 150px;
  background-color: #FAFAFA;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.para-change {
  clear: both;
  font-family: inherit;
  font-size: 15px;
  letter-spacing: normal;
  line-height: inherit;
  padding-top: 13px;
  width: 100%;
}

.btn-next {
  border-style: solid;
  color: #000000;
  border-color: #d19426;
  background-color: #d19426;
  padding-top: 19px;
  padding-right: 25px;
  padding-bottom: 19px;
  padding-left: 25px;
  font-family: inherit;
  font-weight: 600;
  font-size: 15px !important;
  font-size: 0.9rem;
  line-height: 1.2em;
  border-radius: 5px;
}

.upload-drop {
  border: 1px dashed #ccc;
  width: 100%;
  height: 150px;
  text-align: center;
}

.drop-text {
  font-size: 15px;
  font-weight: 500;
  margin-top: 12px;
}

.hide_file {
  padding: 5px 10px;
  background: #d19426;
  border: 1px solid #d19426;
  position: relative;
  color: #fff;
  border-radius: 2px;
  font-size: 18px;
  text-align: center;
  height: 45px;
  font-family: sans-serif;
  /* float: left; */
  font-weight: 600;
  margin: 0 auto;
  width: 20%;
  cursor: pointer;
}

.hide_input {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
}

.bg-gradient-secondary {
  background-color: #fff;
  color: #6b7280;
  text-decoration: none;
  font-size: 20px;
  font-family: inherit;
  font-weight: 600;
  padding-bottom: 15px;
}

.checkbox-label {
  font-size: 16px;
  margin-top: 5px;
  padding-top: 4px;
  font-weight: 400 !important;
  font-family: Roboto;
}

.creative-para {
  font-size: 19px;
  font-family: sans-serif;
}

.bold-name {
  font-size: 18px;
}

.read-hindi {
  font-size: 17px;
  text-decoration: underline;
  color: #134f5c !important;
  cursor: pointer;
}





/* <Arun> */

/* .event-calendar-container {
  padding: 20px;
} */

.calendar-event {
  cursor: pointer;
  /* background-color: #134f5c !important; */
  color: white;
  /* padding: 5px; */
  border-radius: 3px;
  /* margin: 5px; */
  transition: background-color 0.2s ease;
}

.rbc-event-label {
  display: none !important;
}

.calendar-event:hover {
  /* background-color: #134f5c !important; */
}

.rbc-event {
  background-color: #134f5c !important;
  font-size: 14px;
}

/* .rbc-event-content{
  background-color: #134f5c !important;
}
.rbc-event {
  background-color: #134f5c !important;
} */
/* 
.calendar-event:hover {
  background-color: #d19426 !important;
} */
.rbc-event:hover {
  background-color: #d19426 !important;
}

/* .rbc-event-content:hover{
  background-color: #d19426 !important;
} */



.rbc-toolbar-label {
  font-size: 26px !important;
}

/* Tooltip container */
.custom-tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 25% !important;
}

.custom-tooltip img {
  /* max-width: 100%; */
  height: auto;
  width: 300px;
  /* object-fit: contain; */
}

.custom-tooltip h1 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.custom-tooltip h4 {
  font-size: 14px;
  margin: 0;
}






/* .custom-tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  max-width: 300px;
} */

.custom-tooltip p {
  margin: 0;
}

.custom-tooltip img {
  /* max-width: 100%; */
  height: auto;
}



.Toastify div {
  font-size: 15px;
}

.angle-left {
  margin-top: 4px;
  margin-right: 6px;
  font-size: 15px;
}

.event-individual-des {
  font-size: 18px;
  font-family: "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  margin: 20px 0px;
  color: #141827 !important;
  font-style: inherit;
  font-weight: inherit;
}

.website-color {
  color: #007bff;
  font-size: 16px;
}

.btn-primary:hover {
  color: #070000 !important;
  background-color: #d19426 !important;
  border-color: #d19426 !important;
}

.btn-primary:focus {
  color: #070000 !important;
  background-color: #d19426 !important;
  border-color: #d19426 !important;
}

.btn-primary {
  color: #070000 !important;
  background-color: #d19426 !important;
  border-color: #d19426 !important;
}

.btn-secondary {
  color: #070000 !important;
  background-color: #d19426 !important;
  border-color: #d19426 !important;
}

.btn-secondary:hover {
  color: #070000 !important;
  background-color: #d19426 !important;
  border-color: #d19426 !important;
}

.btn-secondary:focus {
  color: #070000 !important;
  background-color: #d19426 !important;
  border-color: #d19426 !important;
}

p.label-allartisan {
  margin-top: 0;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: black !important;
  margin-bottom: 1rem;
}

/* p{
  margin-top: 0;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: black !important;
  margin-bottom: 1rem;
} */


/* src/App.css */

@font-face {
  font-family: 'Hong Kong';
  src: url('../public/fonts/Fontspring-DEMO-hongkong-regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
  /* Add additional font file formats as needed */
  font-weight: normal;
  font-style: normal;
}


/* Apply Hong Kong font to specific elements */
.hongkong-font {
  font-family: 'Hong Kong', sans-serif;
}

/* Apply Roboto font to specific elements */
.roboto-font {
  font-family: 'Roboto', sans-serif;
}



/* new header */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Hongkong&display=swap');

body {
  font-family: 'Roboto';
  /* Apply the Roboto font to the entire component */
}

/* Styling for the transparent navbar */
.navbar {
  transition: background-color 0.3s ease;
  background-image: none;
  /* Remove background image */
  font-family: 'HongKong';
  font-weight: 400;

}

.navbar-yellow {
  margin-top: 0px;
  background-color: #CC7722 !important;
}

.nav-link-textblack {
  color: black !important;
}

.nav-link-textwhite {
  color: white !important;
}

/* Styling for the parallax effect */
.parallax {
  background-image: url('../src/Images/cd_backgroundimage.png');
  /* background-image: url('C:\Users\thiru\Downloads\test-app\test-app\src\assets\cd_backgroundimage.png'); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
  /* Adjust this as needed */
}

.event_parallax {
  background-image: url('../src/Images/event banner.png');
  /* background-image: url('C:\Users\thiru\Downloads\test-app\test-app\src\assets\cd_backgroundimage.png'); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  /* Adjust this as needed */
}

.opportunity_parallax {
  background-image: url('../src/Images/opportunity banner.png');
  /* background-image: url('C:\Users\thiru\Downloads\test-app\test-app\src\assets\cd_backgroundimage.png'); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  /* Adjust this as needed */
}

.artisan_parallax {
  background-image: url('../src/Images/Artisans banner .png');
  /* background-image: url('C:\Users\thiru\Downloads\test-app\test-app\src\assets\cd_backgroundimage.png'); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
  /* Adjust this as needed */
}

.parallax-content {
  text-align: center;
  position: absolute;
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Center both horizontally and vertically */
  width: 100%;
  /* Make the container full width */
  color: white;
  /* Text color for better visibility */
  z-index: 1;
  /* Ensure the text is above the background */
}

.nav-item {
  font-size: 20px;
}

/* hari css */
.multiple-img-filename {
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-top: 10px;
  margin-right: 15px;
}

.img-delete-icon {
  margin-top: 16px;
  font-size: 16px;
  cursor: pointer;
}



/* Add these styles to your CSS file */
.social-share-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure the modal appears on top of other content */
}

.social-share-modal {
  height: 157px;
  background-color: #fff;
  /* Modal background color */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  /* Ensure the modal appears on top of the overlay */
}

/* Add any other styles you need for the modal content */
.social-share-content {
  margin-top: 43px;
  text-align: center;
}

/* Style the close button */
.social-share-content button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.social-share-content button:hover {
  background-color: #d32f2f;
}

.art_form_height {
  margin-top: 150px;
}

.artisan_top_height {
  margin-top: 100px !important;
}

.footer_decoration {
  text-decoration: none;
}

/* .footer_decoration:hover{
  text-decoration: none !important;
  color: white !important;
} */

.footer_a_tag {
  font-size: 14px !important;
  color: #fff !important;
  cursor: pointer !important;
}

.footer_a_tag:hover {
  color: #fff !important;
}


.breadcrum_text {
  text-decoration: none !important;
  color: #134F5C;
  font-family: Roboto;
  font-weight: 400;
  line-height: 19px;
  font-size: 15px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  margin-top: 200px !important;
  height: fit-content !important;
  /* text-align: center; */
  margin: 0 auto;
  width: fit-content !important;
}

/* SPINNER STYLES */
.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  /* Dark overlay background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  /* Ensure it's above everything else */
}

.pulse-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulse-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3ff9dc;
  /* Color of the bubbles */
  margin: 0 5px;
  /* Adjust spacing between bubbles */
  animation: pulse 1s infinite;
  /* Add pulse animation */
}

/* Keyframes for the pulse animation */
@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0.5;
    transform: scale(0.75);
  }
}



.rbc-month-view {

  /* Set a fixed height for each day cell */
  .rbc-month-row {
    .rbc-row-bg+.rbc-row-content {
      height: 200px;
      /* Adjust the height as needed */
      overflow-y: auto;
    }
  }
}

.texbannerevent1 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 50px;
  margin-top: 10px;
}

.pagination .page-item.active .page-link {
  background-color: #134f5c;
  border-color: #134f5c;
  color: #fff !important;
}

.individual-para span {
  font-size: 18px;
  font-family: 'Roboto';
  background-color: #fff !important;
  text-align: justify;
}

.individual-para p {
  font-size: 18px;
  font-family: 'Roboto';
  text-align: justify;
  color: #000;
}

.individual-para li {
  font-size: 15px;
  font-family: 'Roboto';
}

.individual-para ul {
  list-style-type: revert;
}

.ql-editor li {
  font-size: 15px;
  font-family: 'Roboto';
}

.admin-close {
  background: rgb(19, 79, 92) !important;
  font-size: 15px !important;
  color: white !important;
  width: fit-content !important;
}

.rbc-day-slot .rbc-event {
  border: none !important;
}

/* .rbc-row-segment a:hover{
  background-color: #d19426 !important;
}
.rbc-event-content:hover {
  background-color: #d19426 !important;
}
.rbc-selected:hover {
  background-color: #d19426 !important;
} */


.active_pagination_button {
  background-color: #134f5c !important;
  color: #fff !important;
}


.active>.page-link,
.page-link.active {
  z-index: 0 !important;
}

div#viewerContainer {
  margin-top: 50px !important;
}

.pdfViewer .page {
  border: none !important;
}

.mobile__pdf__container #viewerContainer .pdfViewer {
  overflow: hidden !important;
}
@media only screen and (max-width: 600px) {
  .custom-tooltip {
    width: 70% !important;
  }
}


.rpv-core__page-layer.rpv-core__page-layer--single {
  width: 100% !important;
}
canvas{
  width: 100% !important;
}
.rpv-core__canvas-layer{
  width: 100% !important;
}