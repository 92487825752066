#myDiv {}

.txtmemheader {
    text-align: center;
    color: #ffffff;
    margin-top: 136px;
    font-size: 47px;

}

.txtmemone {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    margin-top: 65px;
}

.txtmemtwo {
    color: #ffffff;
    text-align: center;
    font-size: 20px;
}

.txtmemthree {
    color: #ffffff;
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
}

.btncolour {
    background-color: #D19426 !important;
    width: 124px;
    height: 39px;
    margin-left: 200px;
    color: #000 !important;
    font-family: Roboto;
    font-size: 15px !important;
    border-radius: 6px !important;
    border: none;

}

.txtmargintop {
    margin-top: 33px;
    margin-bottom: 20px;
}

.btnstyleone {
    color: #000 !important;
    border-radius: 17px !important;
    width: 131px;
    background-color: #E7EDEF !important;
    margin-left: 12px;
    border: none !important;
    font-size: 16px !important;
    margin-bottom: 10px;
}


.btnsizeartone {
    margin-left: 147px;
    margin-bottom: 10px
}

.imgvieimageone {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.txtarjun {
    margin-left: 50px;
    /* text-align: center; */
}

.txteventcol {
    font-family: Roboto;
    color: #000;
}

.txtdesigner {
    text-align: center;
    font-size: 15px;
    color: #B2B2B2;
}

.image-top {
    margin-top: 30px;
}

.image-top-one {
    margin-top: 30px;
    margin-bottom: 35px;
}

.txtcarbo {
    text-align: center;
    font-size: 15px;
}

.txt123 {
    text-align: center;
    width: 10%;
    height: 32px;
    margin: 0px;
    padding: 0px;
    margin-left: 73px;
    margin-right: -63px
}

.txt1234 {
    text-align: center;
    width: 10%;
    height: 32px;
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
    color: #134F5C;
}

.btnstyleone {
    margin-top: 35px;
    border-radius: 37px !important;
    padding: 8px !important;
    width: 150px;
    background-color: #E7EDEF !important;
    margin-left: 12px;
    border: none !important;
    font-size: 16px !important;
    height: 38px;
    color: #000 !important;
}

.header_toggle1 {
    color: #000000 !important;

}


.margtt {
    margin-top: 7px;
}

.alleventsi {
    margin-left: 200px !important;
    padding: 50px;
}

.txtallst {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
}

.btnaddoppo {
    color: #000 !important;
    background-color: #D19426 !important;
    height: 43px;
    font-weight: bold !important;
    font-size: 15px !important;
    border-radius: 5px !important;
}

.checkbox-labelone {
    font-size: 16px;
    margin-top: 5px;
    padding-top: 4px;
    font-weight: 500;
    color: #333333;
}

.btnaddoppo {
    margin-left: 412px;
}

.martopcss {
    margin-top: 30px
}

.textcenter {
    padding-top: 18px;
    font-size: 15px;
    text-align: center;
    margin-right: 15px;
    font-family: Roboto;
}

.txtbottomt {
    background-color: #D19426;
}

.txtbottomt .btnstyle {

    margin-top: 35px;
    border-radius: 7px !important;
    padding: 8px !important;
    width: 131px;
    background-color: #234F5C !important;
    margin-left: 12px;
    border: none !important;
    font-size: 16px !important;
    color: #fff !important;
}

.dvpformmrg {
    margin-left: 30px;
}

.mrgbutton {
    margin-top: 50px
}




/* <Arun> */
.opportunity-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.opportunity-card {
    border: 1px solid #ccc;
    margin: 10px;
    padding: 15px;
    max-width: 300px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

.opportunity-card-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.opportunity-card-details {
    text-align: start;
    padding: 10px;
    padding-left: 0px !important;
}

.location {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.txtprsize {
    margin: 0;
    color: #999999;
    font-weight: 400;
    font-family: Roboto;
    font-size: 14px;
    line-height: 25px;
}



/* Event_individual*/

/* Container styles */
.container {
    margin-top: 20px;
}

/* Event Title styles */
.event-title h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Event Start Date styles */
.event-start-date p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
}

/* Event Image styles */
.event-image img {
    max-width: 100%;
    max-height: 200px;
    /* Set the desired maximum height */
    width: auto;
    height: auto;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Event Description styles */
.event-description p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

/* Details styles */
.event-details h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.event-details p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
}

.title-event {

    color: #000000;
    font-size: 23px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
}

.locationcol {
    color: #828282;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 25px;
    font-style: italic;
    margin-top: 5px;

}
.location-added {
    color: #828282;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 22px;
    font-style: italic;
    
}

.locationcol-landing {
    color: #828282;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 25px;
    font-style: italic;
    margin-top: 5px;

}

.txtmemopporind {
    font-family: Roboto;
    text-align: center;
}

.textcenter {
    font-family: Roboto;
    text-align: center;
}

.oppcard-container {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 15px;
    padding-left: 15px;
}

/* .eventopp-header{max-width: 1180px;}
.eventopp-container .pagination-nav{background-color: transparent;} */

.artisan-link {
    text-decoration: none !important;
    vertical-align: middle;
}

.find-float {
    float: right;
}

@media only screen and (max-width:768px) {
    .oppcard-container {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    .oppotunity_banner_small_text {
        /* width: 619px; */
        /* margin-left: 410px; */
        /* height: 30px; */
    }
}


@media only screen and (max-width: 600px) {
    button.btnaddoppo.btn.btn-secondary {
        width: 95%;
        margin-left: 10px !important;
    }

    .custom-checkbox {
        margin-left: 10px !important;
    }

    .searchevent {
        width: 95% !important;
    }

    .find-float {
        float: none;
    }

    .inptsearch1 {
        width: auto;
        margin-top: 5px;
    }

    .banner_text {
        margin: 3px 10px !important;
    }

    .mobile-top {
        margin-top: 35px !important;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    button.btnaddoppo.btn.btn-secondary {
        width: 100%;

    }

    .oppotunity_banner_small_text {
        /* width: 619px; */
        /* margin-left: 410px; */
        /* height: 30px; */
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    button.btnaddoppo.btn.btn-secondary {
        width: 100%;

    }

    .oppotunity_banner_small_text {}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    button.btnaddoppo.btn.btn-secondary {
        width: 100%;

    }

    .oppotunity_banner_small_text {}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    button.btnaddoppo.btn.btn-secondary {
        width: 25%;
        float: right;
    }

    .oppotunity_banner_text {
        font-size: 40px;
        margin-top: 120px;
        margin-bottom: 2rem;
        /* margin-top: 5rem; */
    }

    .oppotunity_banner_small_text {
        width: 100rem;
        margin-left: 200px;
        height: 118.42px;
    }
}

.cssheight {
    margin-right: 5px;
    font-size: 14px;
    margin-top: 5px;
}


.nav-link:hover {
    color: #fff !important;
    text-decoration: none;
}

@media (max-width: 768px) {
    .parallax h1 {
        font-size: 35px !important;
    }
}


.banner_text {
    font-family: Roboto;
    font-size: 20px;
    margin-top: 50px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0em;
    color: #FFFFFF;
    text-align: center !important;
}

/* nithya */
.search-icon1 {
    font-size: 15px;
    margin-left: 7px;
    margin-bottom: 5px;
}

.txtbacgroundimg .parallax .smallpara-font .banner_text {
    font-size: 20px;
    margin-top: 50px;

}


.opportunity .texbanneropportunity {
    font-family: Roboto;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 50px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: justify;
}