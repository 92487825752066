.fullbodymarg {
    margin-top: 60px;
}

.alignment {
    width: 100%;
    margin-left: -5px;
}

.photosize {
    clear: both;
    font-size: 15px;
    letter-spacing: normal;
    line-height: inherit;
    margin-bottom: 20px;
    margin-top: 5px;
    padding-left: 8px;

}

.fullbodymarg .form-control {
    background-color: #FAFAFA;
}

.txtcolourtwo .form-control {
    background-color: #FAFAFA;
}

.txtcolourtwo .text-area {
    background-color: #FAFAFA;
}

.textareaone {
    width: 100%;
    height: 100px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1.4rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057
}

.inputbottom {
    margin-bottom: 10px;
}

.countywidth {
    width: 71% !important;
}

.countywidthone {
    width: 98% !important;
}

.fullbodymarg .inputlogo {
    font-size: 20px;
    width: 100%;
    line-height: 1.3;
    font-weight: 400;
}

.fullbodymarg .label-head {
    font-size: 15px !important;
    font-family: Roboto;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 10px
}

.fullbodymarg .nextmrg {
    margin-left: 15px;
    margin-top: 25px;
    margin-bottom: 20px
}

.optionclass .whatsarea {
    margin-top: 20px;
    margin-bottom: 15px;
}

.member_form_height .gsection_title {
    font-size: 18px;
    font-weight: 600;
    font-family: Roboto;
    line-height: 1.2em;
    text-align: center;
    color: #134F5C !important;

}

.formtwo .bg-gradient-secondary {

    border: 1px solid #000;
    border-radius: 5px;
    margin-right: 20px !important;
    padding-top: 12px;
    height: 55px;
    width: 110px;
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 500;

}

.member_form_height .bg-gradient-secondary-next {
    border-style: solid;
    color: #000000;
    background-color: #D19426;
    border-radius: 5px;
    margin-right: 20px !important;
    padding-top: 0px;
    height: 40px;
    height: 55px;
    width: 110px;
    border-color: #d19426;
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 500;
}

.fullbodymarg .paading {
    padding-right: 20px;
}

.member_form_height .organidisplay {
    display: flex;
}

.member_form_height .interset-cd {
    display: flex;
}

.whatsareainter-cd {
    margin-left: 5px;
}

.interested-label {
    margin-bottom: 0;
    max-width: calc(100% - 32px);
    vertical-align: middle;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
}

.optionclass .interwidth {
    display: flex;
}

.member_form_height {
    margin-top: 150px !important;
}
.txtssss{
    font-size: 16px;
}
.member_form_height .memberarudrpform {
    border-width: 1px;
    border-style: solid;
    border-color: var(--ast-border-color);
    border-radius: 2px;
    background: #fafafa;
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 11px;
    border: 1px solid #DDDDDD;
    width: 100% !important;
    -webkit-appearance: none;
}

.selectwrappermem ::after {
    content: "\25BC" !important;
    position: absolute;
    top: 79%;
    right: 30px;
    transform: translateY(-50%);
    pointer-events: none;
}
.selectwrappermemlang ::after {
    content: "\25BC" !important;
    position: absolute;
    top: 79%;
    right: 20px;
    transform: translateY(-50%);
    pointer-events: none;
}


@media only screen and (max-width: 600px) {
    .selectwrappermemlang ::after {
        content: "\25BC" !important;
        position: absolute;
        top: 79%;
        right: 20px;
        transform: translateY(-50%);
        pointer-events: none;
    }
}

.text_size{
    font-size: 16px !important;
}

.checkbox-label-agree{
    font-weight: bold !important;
    font-size: 16px;
    margin-top: 5px;
    padding-top: 4px;
    
    font-family: Roboto;

}
p.creative-para{

    color: #134F5C;
    font-size: 17px !important;
}

b.bold-name{
    color: #134F5C;
    font-size: 16px !important;
}

li.creative-para{
    color: #134F5C;
font: 16px sans-serif !important;
color: #134F5C !important;
}

input[type='file'] {
    color: rgba(0, 0, 0, 0)
  }