.smallerformcss .label-head {
    font-size: 15px !important;
    font-family: Roboto;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 10px
}

.smallerformcss .textareaone {
    width: 100%;
    height: 300px;
    background-color: #FAFAFA;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1.4rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.smallerformcss .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #FAFAFA;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

}
.smallerformcss .btn-next {
    border-style: solid;
    color: #000;
       background-color: #D19426;
    padding-top: 19px;
    padding-right: 25px;
    padding-bottom: 19px;
    padding-left: 25px;
    font-family: inherit;
    font-weight: 600;
    font-size: 15px !important;
    font-size: 0.9rem;
    line-height: 1.2em;
    border-radius: 5px;
}
.smallerformcss .btn-next:hover{
    background-color: #D19426;
    color: #000;
}

.smaller_form_height{
    margin-top: 150px;
}