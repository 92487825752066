.addopportunitycs .alignmentone {
    width: 100% !important;
    margin-left: -5px;
    font-size: 20px !important;
    font-weight: 500 !important;
}

.addopportunitycs .txtaccfile {
    font-size: 15px;
    font-family: Roboto;
    margin-top: 10px;
}

.addopportunitycs .inputtextsize {
    height: 280px !important;
}

.addopportunitycs .form-controlone {
    display: block;
    width: 10%;
    height: 40px !important;
    width: 18%;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #FAFAFA;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.addopportunitycs .form-control {
    background-color: #FAFAFA;
}

.addopportunitycs .label-head {
    margin-left: 0px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-family: Roboto;
}

.addopportunitycs .btn-nextone {
    border-style: solid;
    color: #000000;
    border-color: #d19426;
    background-color: #d19426;
    padding-top: 19px;
    padding-right: 25px;
    padding-bottom: 19px;
    padding-left: 25px;
    font-family: Roboto;
    font-weight: 600;
    font-size: 15px !important;
    font-size: 0.9rem;
    line-height: 1.2em;
    border-radius: 5px;
    margin-bottom: 100px !important;
}

.addopportunitycs .inputfileupload {
    font-size: 20px;
    width: 100%;
    line-height: 1.3;
    font-weight: 400;
}

.opportunity-height {
    border: 1px solid;
    margin-top: 150px !important;
}

.red-background {
    border-style: solid;
    color: #000000;
    border-color: #d19426;
    background-color: #d19426;
    padding-top: 19px;
    padding-right: 25px;
    padding-bottom: 19px;
    padding-left: 25px;
    font-family: Roboto;
    font-weight: 600;
    font-size: 15px !important;
    font-size: 0.9rem;
    line-height: 1.2em;
    border-radius: 5px;

}

.ql-container .ql-editor {
    background-color: #FAFAFA;
}

.addopportunitycs .addopportunitydrpform {
    border-width: 1px;
    border-style: solid;
    border-color: var(--ast-border-color);
    border-radius: 2px;
    background: #fafafa;
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 11px;
    border: 1px solid #DDDDDD;
    width: 100%;
    -webkit-appearance: none;
}

.selectwrapperoppor ::after {
    content: "\25BC" !important;
    position: absolute;
    top: 79%;
    right: 29px;
    transform: translateY(-50%);
    pointer-events: none;
}


.opportbanerimg {
    margin-top: 70px !important;
    margin-bottom: 2px;
}

.addopportunitycs .quillrefdoc {
    height: 200px;
}

.cd-exclusive1 {
    margin-top: 15px !important;
}

@media only screen and (max-width:768px) {
    .cd-exclusive {
        margin-top: 85px !important;
    }

    .form-controlone {
        width: 100% !important;

    }
}